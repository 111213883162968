<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <b-modal :id="'modal-create-comentario-' + modal " size="lg" :title="titulo">
            <div class="row">
                <div class="form-group col-md-10">
                    <label>Observación*</label>
                    <textarea class="form-control" v-model="formComentario.observacion"></textarea>
                </div>
                <div class="col-md-2 align-self-end" style="margin-bottom:30px;">
                    <a @click="formComentario.observacion=''" class="btn btn-primary btn-sm">Limpiar</a><a href="#" v-b-tooltip.hover title="Limpiar texto que se está escribiendo"><font-awesome-icon :icon="['fas', 'info-circle']" /></a>
                </div>
            </div>
            <div class="row" v-if="comentarios.length > 0">
                <div class="col-md-12">
                    <ul class="list-group comment__list">
                        <li class="list-group-item comment__item" v-for="comentario in comentarios" v-bind:key="comentario.id">
                            <div class="comment__header">
                                <h3 class="comment__user">{{comentario.user__get_nombre_completo}}</h3>
                                <small class="comment__date">{{comentario.fechahora | dateTimeFormat}}</small>
                                <div class = "comment__delb">
                                    <a @click="deleteAlert(comentario)" role="button" v-show="isSuperAdmin" class="btn btn-danger btn-sm"><font-awesome-icon :icon="['fas', 'trash-alt']" /></a>
                                </div>
                            </div>
                            <p class="comment__description">{{ comentario.observacion }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <template #modal-footer>
<!--                 <b-button size="md" variant="outline-secondary" @click="closeModal();getData()">
                    Cancelar
                </b-button> -->
                <a @click="createComentario()" class="btn btn-primary">Guardar <font-awesome-icon :icon="['fas', 'arrow-right']" /></a>
            </template>
        </b-modal>
        <div class="mb-5">
            <b-button @click="openModal()" class="btn btn-primary btn-sm">
                <font-awesome-icon :icon="['fas', 'comment']" /> Comentarios
            </b-button>
            <span v-if="comentarios.length > 0" class="notificaciones__number">{{comentarios.length}}</span>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'

const Comentario = resource('/api/api/crear_comentarios', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'ModalComentarios',
    data () {
        return {
            comentarios: [],
            formComentario: {},
            titulo: this.$attrs.titulo,
            modal: this.$attrs.modal,
            fichaId: this.$attrs.ficha,
            instrumentoId: this.$attrs.instrumento,
            agenciaId: this.$attrs.agencia,
            seccionId: this.$attrs.seccion,
            subseccionId: this.$attrs.subseccion,
            teoriaCambioId: this.$attrs.teoriaCambio,
            indicadorId: this.$attrs.indicador,
            tipoIndicadorId: this.$attrs.tipoIndicador,
            monitoreoInstrumentoId: this.$attrs.monitoreoInstrumento,
            resultadoId: this.$attrs.resultado,
            show: false,
            isSuperAdmin: false
        }
    },
    methods: {
        getData () {
            Comentario.query({
                ficha: this.fichaId,
                instrumento: this.instrumentoId,
                agencia: this.agenciaId,
                seccion: this.seccionId,
                subseccion: this.subseccionId,
                teoria_cambio: this.teoriaCambioId,
                indicador: this.indicadorId,
                tipo_indicador: this.tipoIndicadorId,
                monitoreoinstrumento: this.monitoreoInstrumentoId,
                resultado: this.resultadoId
            }).then((res) => {
                this.comentarios = res.data
            }, (error) => {
                console.log(error)
            })
        },
        openModal () {
            // this.getData()
            this.$root.$emit('bv::show::modal', 'modal-create-comentario-' + this.modal)
        },
        closeModal () {
            this.$root.$emit('bv::hide::modal', 'modal-create-comentario-' + this.modal)
        },
        deleteAlert (comentario) {
            this.$swal({
                title: 'Vas a eliminar el Comentario "' + comentario.observacion + '"',
                text: '¿Estás seguro? Se perderán todos los datos',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteComentario(comentario)
                }
            })
        },
        createComentario () {
            this.formComentario.ficha = this.fichaId
            this.formComentario.instrumento = this.instrumentoId
            this.formComentario.agencia = this.agenciaId
            this.formComentario.seccion = this.seccionId
            this.formComentario.subseccion = this.subseccionId
            this.formComentario.teoria_cambio = this.teoriaCambioId
            this.formComentario.tipo_indicador = this.tipoIndicadorId
            this.formComentario.indicador = this.indicadorId
            this.formComentario.monitoreoinstrumento = this.monitoreoInstrumentoId
            this.formComentario.resultado = this.resultadoId

            this.show = true
            axios({
                method: 'post',
                url: '/api/api/crear_comentarios/',
                data: this.formComentario
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    this.closeModal()
                    this.formComentario = {}
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Comentario creado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al crear el Comentario'
                    })
                })
        },
        deleteComentario (comentario) {
            this.show = true
            axios({
                method: 'delete',
                url: '/api/api/crear_comentarios/' + comentario.id + '/'
            })
                .then((response) => {
                    this.show = false
                    this.getData()
                    // this.$router.push({ name: 'ListarAgencias' })
                    this.closeModal()
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Comentario eliminado exitosamente'
                    })
                })
                .catch((response) => {
                    this.show = false
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error al eliminar el comentario'
                    })
                })
        }
    },
    mounted () {
        this.getData()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.is_superuser || this.usuario.is_staff) {
                this.isSuperAdmin = true
            }
        })
            .catch((error) => {
                console.log(error)
                this.show = false
            })
    }
}
</script>
